/** @jsx jsx */
import { Link, graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import React, { FunctionComponent } from "react";
import { Box, Text, jsx } from "theme-ui";

import { theme } from "../../../../libs/theme/src";

import { BlogPost, Layout, SEO } from "../components";

export const BlogIndex: FunctionComponent<{ data: any }> = ({ data }) => {
  const posts = data.allMdx.edges.map(({ node }, idx, coll) => {
    return (
      <Box key={idx}>
        <BlogPost
          title={node.frontmatter.title}
          date={node.frontmatter.date}
          link={node.fields.slug}
        >
          <MDXRenderer sx={{ fontSize: "1rem", color: "rgb(45, 55, 72)" }}>{node.body}</MDXRenderer>
        </BlogPost>
        {idx < coll.length - 1 && <Divider />}
      </Box>
    );
  });

  return (
    <Layout>
      <SEO useTitleTemplate={false} />
      {posts}
    </Layout>
  );
};

const Divider = () => {
  return <Text sx={{ textAlign: "center", color: "gray.5", fontSize: "sm", my: 12 }}>⁂ ⁂ ⁂</Text>;
};

export default BlogIndex;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          body
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            teaser
            date(formatString: "MMMM D, YYYY")
          }
        }
      }
    }
  }
`;
